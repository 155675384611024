import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTicker } from "../../services";

export const getTickerAction = createAsyncThunk(
  "getTicker",
  async (params) => {
    const response = await getTicker(params);
    return response.data;
  }
);
