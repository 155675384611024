import { createSlice } from "@reduxjs/toolkit";
import { getWiseConfigAction } from "../actions/wiseConfigAction";
import cardConfig from "../../assets/config/cardConfig";
import wiseAppConfig from "../../assets/config/demo/companyConfig/S-NDA";
import demoSiteConfig from "../../assets/config/demoSiteConfig";

const initialState = {
  data: {},
  companyConfig: null, // To hold parsed companyConfig
  cardConfig: null, // To hold parsed cardConfig
  loading: false,
  error: null,
};

const wiseConfigSlice = createSlice({
  name: "wiseConfig",
  initialState,
  reducers: {
    setCompanyConfig: (state, action) => {
      state.companyConfig = action.payload;
    },
    setCardConfig: (state, action) => {
      state.cardConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWiseConfigAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getWiseConfigAction.fulfilled, (state, action) => {
      const data = action.payload?.data?.companyConfiguration;
      try {
        state.companyConfig = JSON.parse(data?.companyConfig);
        state.cardConfig = demoSiteConfig?.isDemo
          ? cardConfig
          : JSON.parse(data?.cardConfig);
        // state.companyConfig = wiseAppConfig;
        // state.cardConfig = cardConfig;
      } catch (error) {
        state.error = "Error parsing configuration data";
      }
      state.loading = false;
    });
    builder.addCase(getWiseConfigAction.rejected, (state, action) => {
      state.fetchError = action.error;
      state.loading = false;
    });
  },
});
export const { setCompanyConfig, setCardConfig } = wiseConfigSlice.actions;
export const selectCompanyWidgetConfig = (state) =>
  state.wiseConfig.companyConfig;
export const selectCardConfig = (state) => state.wiseConfig.cardConfig;
export default wiseConfigSlice;
