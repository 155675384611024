import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadVariableStyles } from "./utils";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import ToastDialog from "./components/common/ToastDialog";
import i18n from "./translator/i18Helper";

loadVariableStyles();
function Root() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    const rootElement = document.getElementById("root");
    if (lang?.toLowerCase() === "ar-ae") {
      rootElement.classList.add("root-arabic");
    }
    lang ? i18n.changeLanguage(lang) : i18n.changeLanguage("en");
  }, []);
  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ToastDialog />
        <Root />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
