import {
  getDateRanges,
  getFutureYearsArray,
  getYearsArray,
} from "../utils/common";
import { GET_INTRADAY_MAIN, GET_SHARE_PERFORMANCE } from "./query";

/** TICKER PERFORMANCE QUERY */
export const TICKERPERFORMANCE = (param) => {
  const ids = param?.accessConfig?.shares;
  return `sharePerformance: ${GET_SHARE_PERFORMANCE(ids)}`;
};
/** PEERS PROFIT LOSS QUERY */
export const PEERSPROFITLOSSCARD = (param, returnName = "peersProfitLoss") => {
  const ids = param?.accessConfig?.peers;
  const dateRange = getDateRanges()?.todayRange;
  return `${returnName}: ${GET_INTRADAY_MAIN(ids, dateRange)}`;
};
/** INDICES PROFIT LOSS QUERY */
export const INDICESPROFITLOSSCARD = (param) => {
  const ids = param?.accessConfig?.indices;
  const dateRange = getDateRanges()?.todayRange;
  return `indicesProfitLoss: ${GET_INTRADAY_MAIN(ids, dateRange)}`;
};
/** TICKER TODAY INFORMATION */
export const TICKERCHARTTODAY = (param, duration = "today") => {
  const ids = param?.accessConfig?.shares;
  const dateRangeFn = getDateRanges();
  let isToday = true;
  let dateRange = dateRangeFn?.todayRange;
  if (duration !== "today") {
    isToday = false;
    dateRange =
      duration === "weekly"
        ? dateRangeFn?.last7DaysRange
        : dateRangeFn?.last30DaysRange;
  }
  return `shares: ${GET_INTRADAY_MAIN(ids, dateRange, isToday)}`;
};
/** TICKER TODAY INFORMATION  LARGE*/
export const TICKERCHARTTODAYLARGE = (param) => TICKERCHARTTODAY(param);
/** TICKER WEEKLY INFORMATION  LARGE*/
export const TICKERCHARTWEEKLYLARGE = (param) =>
  TICKERCHARTTODAY(param, "weekly");
/** TICKER MONTHLY INFORMATION  LARGE*/
export const TICKERCHARTMONTHLYLARGE = (param) =>
  TICKERCHARTTODAY(param, "monthly");
/** PEERS TODAY INFORMATION */
export const PEERSCHARTTODAY = (param) => PEERSPROFITLOSSCARD(param, "peers");
/** PEERS TODAY INFORMATION  LARGE*/
export const PEERSCHARTTODAYLARGE = (param) =>
  PEERSPROFITLOSSCARD(param, "peers");
/** FINANCIAL EVENTS */
export const FINANCIALEVENTS = (param) => {
  const today = getDateRanges()?.today;
  const twentyOneDays = getDateRanges()?.future21Day;
  return `financial:company(code: "${param?.company}") {
    fundamental {
      financialEvents(
        where: { startDate: { gte: "${today}T00:00:00Z" lte: "${twentyOneDays}T23:59:59Z"} }
        first: 4
        order: { startDate: ASC }
      ) {
        nodes {
          companyCode
          description
          endDate
          eventDetailId
          eventId
          eventTypeId
          isAllDayEvent
          isDeleted
          isHighlighted
          languageId
          lastUpdated
          linkDescription
          linkUrl
          location
          startDate
          title
        }
      }
      financialEventTypes {
        nodes {
          name
          id
        }
      }
    }
  }`;
};
/** EARNING MARKET */
export const EARNINGMARKET = (param) => {
  const currentYear = getDateRanges()?.currentYear;
  const last4Years = getYearsArray(currentYear, 4);
  return `earningMarket: company(code: "${param?.company}") {
    fundamental {
      actual: incomeStatement(
        source: "financialmodelingprep"
        order: { date: DESC }
        where: {
          period: { in: "FY" }
          year: { in: [${last4Years}] }
        }
      ) {
        items {
          calendarYear
          reportedCurrency
          eps
          netIncome
        }
      }
      estimated: analystEstimates(
        source: "financialmodelingprep"
        order: { date: DESC }
        where: {
          period: { in: "FY" }
          year: { in: [${last4Years}] }
        }
      ) {
        items {
          calendarYear
          periodStart
          reportedCurrency
          estimatedNetIncomeAvg
          estimatedEpsAvg
        }
      }
    }
  }`;
};
/** EARNING SUBSCRIBE */
export const EARNINGSUBSCRIBE = (param) => {
  const today = getDateRanges()?.today;
  const twentyOneDays = getDateRanges()?.future21Day;
  // const twentyOneDays = "2024-12-30";
  const eventTypeIds = param?.accessConfig?.eventTypeId;
  return `earningSubscribe: company(code: "${param?.company}") {
    fundamental {
      financialEvents(
        where: {
          startDate: { gte: "${today}T00:00:00Z" lte: "${twentyOneDays}T23:59:59Z" } 
          eventTypeId: { in: [${eventTypeIds}] }
        }
        first: 1
        order: { startDate: ASC }
      ) {
        nodes {
          companyCode
          description
          endDate
          eventTypeId
          languageId
          lastUpdated
          linkDescription
          linkUrl
          location
          startDate
          title
        }
      }
    }
  }`;
};
/** ANALYST RATING */
export const ANALYSTRATINGMETERCHART = (param) => {
  const dateRange = getDateRanges()?.last6Months;
  return `analystRating: company(code: "${param?.company}") {
    fundamental { 
    analystRecommendation(showDataFrom: "${dateRange}") {
        analystRatingBuy
        analystRatingHold
        analystRatingSell
        analystRatingStrongBuy
        analystRatingStrongSell
        date
      }
    }
  }`;
};
export const ANALYSTRANGESLIDER = (param) => ANALYSTRATINGMETERCHART(param);
/** EARNINGS CANDLE CHART */
export const EARNINGSCANDLECHART = (param) => {
  return `earningsRevenue: company(code: "${param?.company}") {
    fundamental {
      revenueReported: incomeStatement(
        source: "financialmodelingprep"
        order: { date: DESC }
        where: { period: { in: "Q1,Q2,Q3,Q4" } }
        take: 1
      ) {
        items {
          calendarYear
          revenue
          operatingExpenses
          operatingIncome
          netIncome
          costOfRevenue
          period
        }
      }
    }
  }`;
};
/** DIVIDEND LINE CHART */
export const DIVIDENDLINECHART = (param) => {
  const year = getDateRanges()?.currentYear - 5;
  const id = param?.accessConfig?.primaryMarket?.[0];
  return `dividend: instrumentById(id: ${id}) {
    shareName
    currencyCode
    annualDividends(order: { year: ASC }, where: { year: { gte: ${year} } }) {
      year
      yield
      total
    }
  }`;
};
/** LAST DIVIDEND PAID */
export const LASTDIVIDENDPAID = (param) => {
  const id = param?.accessConfig?.primaryMarket?.[0];
  return `lastDividend: instrumentById(id: ${id}) {
    currencyCode
    dividends(order: { exDate: DESC }, first: 1) {
      nodes {
        exDate
        payDate
        recDate
        period
        grossDivAdj
      }
    }
  }`;
};
/** NET INCOME */
export const NETINCOME = (param) => {
  const lastYear = getDateRanges()?.currentYear - 1;
  const fiveYears = getYearsArray(lastYear, 5);
  return `netIncome: company(code: "${param?.company}") {
  fundamental {
      reported: incomeStatement(
        source: "financialmodelingprep"
        where: { period: { in: "FY" }, year: { in: [${fiveYears}] }}
        order: { date: ASC }
      ) {
        items {
          netIncome
          calendarYear
          reportedCurrency
        }
      }
    }
  }`;
};
/** NEWS ANNOUNCEMENTS */
export const NEWSANNOUNCEMENTS = (param) => {
  return `newsAnnouncements: company(code: "${param?.company}") {
  pressReleases(order: { insertedDate: DESC }, first: 10) {
      nodes {
        id
        title
        dateTime
        insertedDate
        messageType {
          name
        }
      }
    }
  }`;
};
/** DOCUMENT TAB */
export const DOCUMENTTAB = (param) => {
  return `documents: company(code: "${param?.company}") {
  fundamental {
      reports(
        where: { languageCode: { eq: "en" } }
        order: {
          year: DESC
          reportParentTypeOrder: ASC
          reportTypeOrder: ASC
          title: ASC
          languageCode: ASC
          languageName: ASC
        }
      ) {
        nodes {
          id
          reportParentTypeDisplayName
          thumbnailFileLocationFull
          title
          reportParentTypeId
        }
      }
    }
  }`;
};
/** DEBT RATIO DOT CHART */
export const DEBTRATIODOTCHART = (param) => {
  const lastYear = getDateRanges()?.currentYear - 1;
  const fiveYears = getYearsArray(lastYear, 5);
  return `debtRatio: company(code: "${param?.company}") {
     fundamental {
      debt_ratio: ratios(
        source: "financialmodelingprep"
        where: {
          period: { in: "FY" }
          year: { in: [${fiveYears}] }
        }
        order: { date: ASC }
      ) {
        items {
          debtRatio
          calendarYear
          date
          period
        }
      }
    }
  }`;
};
/** COMPOUND GROWTH CARD */
export const COMPOUNDGROWTHCARD = (param) => {
  return `compoundGrowth: company(code: "${param?.company}") {
    fundamental {
     financialGrowth(
        source: "financialmodelingprep"
        take: 1
        where: { period: { in: "FY" } }
      ) {
        items {
          calendarYear
          period
          tenYSaleGrowth: tenYRevenueGrowthPerShare
          tenYProfitGrowth: tenYNetIncomeGrowthPerShare
          fiveYSaleGrowth: fiveYRevenueGrowthPerShare
          fiveYProfitGrowth: fiveYNetIncomeGrowthPerShare
          threeYSaleGrowth: threeYRevenueGrowthPerShare
          threeYProfitGrowth: threeYNetIncomeGrowthPerShare
        }
      }
    }
  }`;
};
/** FINANCIAL HEALTH */
export const FINANCIALHEALTH = (param) => {
  const lastYear = getDateRanges()?.currentYear - 1;
  const fiveYears = getYearsArray(lastYear, 5);
  return `financialHealth: company(code: "${param?.company}") {
  fundamental {
      balanceSheet(
        source: "financialmodelingprep"
        where: { year: { in: [${fiveYears}] }, period: {in: "FY" } }
        order: { date: ASC }
      ) {
        items {
          calendarYear
          totalDebt
          totalLiabilities
          reportedCurrency     
        }
      }
    }
  }`;
};
/** VALUATION RATIO CHART */
export const VALUATIONRATIOCHART = (param) => {
  return `valuationRatio: company(code: "${param?.company}") {
   fundamental {
      ratios(
        source: "financialmodelingprep"
        where: { period: {in: "Q1,Q2,Q3,Q4" } }
        take: 5
        order: { date: DESC }
      ) {
        items {
          debtEquityRatio
          returnOnEquity
          period
          date
        }
      }
    }
  }`;
};
/** FINANCIAL PROFITABILITY */
export const FINANCIALPROFITABILITY = (param, count = 5) => {
  const currentYear = getDateRanges()?.currentYear - 1;
  const years = getYearsArray(currentYear, count);
  return `profitability: company(code: "${param?.company}") {
    fundamental {
      incomeStatement(
        where: {
          period: { in: "FY" }
          year: { in: [${years}] }
        }
        source: "financialmodelingprep"
        order: { date: ASC }
      ) {
        items {
          netIncome
          netIncomeRatio
          revenue
          period
          calendarYear
          reportedCurrency
        }
      }
    }
  }`;
};
/** FINANCIALSLARGE */
export const FINANCIALPROFITABILITYLARGE = (param) =>
  FINANCIALPROFITABILITY(param, 7);

/** EPS ANNUAL RATIO CHART */
export const EPSANNUALRATIOCHART = (param) => {
  const currentYear = getDateRanges()?.currentYear - 1;
  const last5Years = getYearsArray(currentYear, 5);
  return `epsAnnual: company(code: "${param?.company}") {
    fundamental {
      incomeStatement(
        source: "financialmodelingprep"
        where: { period: { in: "FY" }  year: { in: [${last5Years}] }}
        order: { date: ASC }
      ) {
        items {
          eps
          calendarYear
          reportedCurrency
        }
      }
    }
  }`;
};
/** RETURN ON ASSETS RATIO CHART */
export const RETURNONASSETSRATIOCHART = (param) => {
  return `returnOnAssets: company(code: "${param?.company}") {
        fundamental {
      ratios(
        source: "financialmodelingprep"
        where: { period: { in: ["Q1", "Q2", "Q3", "Q4"] } }
        order: { date: DESC }
        take: 5
      ) {
        items {
          date
          period
          returnOnAssets
        }
      }
    }
  }`;
};
/** PROFIT AND LOSS */
export const PROFITANDLOSS = (param) => {
  const currentYear = getDateRanges()?.currentYear;
  const last8Years = getYearsArray(currentYear, 8);
  return `profitLoss: company(code: "${param?.company}") {
    fundamental {
      incomeStatement(
        source: "financialmodelingprep"
        where: {
          year: { in: [${last8Years}] }
          period: { in: "FY" }
        }
        order: { date: ASC }
      ) {
        items {
          calendarYear
          sales: revenue
          expenses: operatingExpenses
          operatingProfit: operatingIncome
          operatingProfitPercentage: operatingIncomeRatio
          profitBeforeTax: incomeBeforeTax
          netProfit: netIncome
        }
      }
      ratios(
        source: "financialmodelingprep"
        where: {
          period: { in: "FY" }
          year: { in: [${last8Years}] }
        }
        order: { date: ASC }
      ) {
        items {
          dividendPayout: dividendPayoutRatio
          calendarYear
        }
      }
    }
  }`;
};
/** PEER COMPARISON CHART LARGE */
export const PEERCOMPARISONCHARTLARGE = (
  param,
  returnName = "peersComparison"
) => {
  const last5YearsRange = getDateRanges()?.last5YearsRange;
  const ids =
    returnName === "peersComparison"
      ? param?.accessConfig?.peers
      : param?.accessConfig?.indices;
  return `${returnName}: instrumentByIds(
    ids: [${param?.accessConfig?.primaryMarket},${ids}]
  ) {
    id
    shareName
    currentPrice {
      changePercentage
    }
    market {
      timezone {
        nameIANA
      }
    }
    historicals(
      where: {
        dateTime:  ${last5YearsRange} 
      }
      order: { dateTime: ASC }
      first: 32000
    ) {
      nodes {
        close
        dateTime
      }
    }
  }`;
};
/** INDICES COMPARISON CHART LARGE */
export const INDICESCOMPARISONCHARTLARGE = (param) =>
  PEERCOMPARISONCHARTLARGE(param, "indicesComparison");
/** TREND CHART XL */
export const TRENDCHARTXL = (param) => {
  const last12MonthsRange = getDateRanges()?.last12MonthsRange;
  return `trend: instrumentByIds(
    ids: [${param?.accessConfig?.primaryMarket}]
  ) {
    id
    shareName
    currencyCode
    currentPrice {
      changePercentage
      last
    }
    market {
      timezone {
        nameIANA
      }
    }
    historicals(
      where: {
        dateTime:  ${last12MonthsRange} 
      }
      order: { dateTime: ASC }
      first: 32000
    ) {
      nodes {
        close
        dateTime
      }
    }
  }`;
};
/** REVENUE ANNUAL */
export const REVENUEANNUAL = (param) => {
  const currentYear = getDateRanges()?.currentYear;
  const last5Years = getYearsArray(currentYear, 5);
  return `revenueAnnual: company(code: "${param?.company}") {
    fundamental {
      actual: incomeStatement(
        source: "financialmodelingprep"
        order: { date: ASC }
        where: {
          period: { in: "FY" }
          year: { in: [${last5Years}] }
        }
      ) {
        items {
          calendarYear
          revenue
          reportedCurrency
        }
      }
      estimated: analystEstimates(
        source: "financialmodelingprep"
        order: { date: ASC }
        where: {
          period: { in: "FY" }
          year: { in: [${last5Years}] }
        }
      ) {
        items {
          calendarYear
          periodStart
          reportedCurrency
          estimatedRevenueAvg
        }
      }
    }
  }`;
};
/** EBITA ANNUAL BAR CHART XL */
export const EBITDAANNUALBARCHARTXL = (
  param,
  returnName = "ebitdaAnnualBar"
) => {
  const currentYear = getDateRanges()?.currentYear;
  const last10Years = getYearsArray(currentYear, 10)?.reverse();
  const future3Years = getFutureYearsArray(currentYear, 3);
  const yearsArr = last10Years?.concat(future3Years);
  const [col1, col2] =
    returnName === "ebitdaAnnualBar"
      ? ["ebitda", "estimatedEbitdaAvg"]
      : ["revenue", "estimatedRevenueAvg"];
  return `${returnName}: company(code: "${param?.company}") {
    fundamental {
      actual: incomeStatement(
        source: "financialmodelingprep"
        where: {
          year: { in: [${last10Years}] }
          period: { in: "FY" }
        }
        order: { date: ASC }
      ) {
        items {
          calendarYear
          reportedCurrency
          ${col1}
        }
      }
      estimated: analystEstimates(
        source: "financialmodelingprep"
        where: {
          year: {
            in: [${yearsArr}]
          }
          period: { in: "FY" }
        }
        order: { date: ASC }
      ) {
        items {
          periodStart
          ${col2}
        }
      }
    }
  }`;
};
/** REVENUE ANNUAL BAR CHART LARGE */
export const REVENUEANNUALBARCHARTXL = (param) =>
  EBITDAANNUALBARCHARTXL(param, "revenueAnnualBar");
/** REVENUE STEAM BY COUNTRY */
export const REVENUESTEAMQUERY = (param, setting) => {
  return `${setting?.name}: company(code: "${param?.company}") {
  fundamental{
    segments(
      source: "tikr-morningstar"
      segmentKeyFilter: "${setting?.segment}"
      detailKeyFilter: "${setting?.detail}"
      order: { date: DESC }
      take: 1
    ) {
      items {
        calendarYear
        currency
        period
        segments {
          name
          detail {
            name
            value {
              name
              value
            }
          }
        }
      }
    }
  }
  }`;
};
/** REVENUE STREAM */
export const REVENUESTREAM = (param) => {
  const setting = {
    name: "revenueStream",
    segment: "business",
    detail: "OperatingRevenue",
  };
  return REVENUESTEAMQUERY(param, setting);
};
/** REVENUE STEAM BY COUNTRY */
export const REVENUESTEAMBYCOUNTRY = (param) => {
  const setting = {
    name: "revenueStreamByCountry",
    segment: "geo",
    detail: "TotalAssets",
  };
  return REVENUESTEAMQUERY(param, setting);
};
