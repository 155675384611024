import { createSlice } from "@reduxjs/toolkit";
import { getTickerAction } from "../actions/tickerAction";
const initialState = {
  data: [],
  loading: false,
  error: null,
};
const tickerSlice = createSlice({
  name: "ticker",
  initialState,
  reducers: {
    setTicker: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTickerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTickerAction.fulfilled, (state, action) => {
      const data = action.payload?.data?.shares;
      try {
        state.data = data;
      } catch (error) {
        state.error = "Error ticker assigning data";
      }
      state.loading = false;
    });
    builder.addCase(getTickerAction.rejected, (state, action) => {
      state.fetchError = action.error;
      state.loading = false;
    });
  },
});
export const { setTicker } = tickerSlice.actions;
export const tickerList = (state) => state.ticker.data;
export default tickerSlice;
