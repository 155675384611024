import { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/index.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
/** LAYOUTS */
const DefaultLayout = lazy(() =>
  import("./components/layout/default-layout.component")
);

/** PAGES */
const Home = lazy(() => import("./pages/Home"));
const Announcement = lazy(() => import("./pages/Announcement"));
const AnnouncementDetail = lazy(() => import("./pages/AnnouncementDetail"));
const FinancialReports = lazy(() => import("./pages/FinancialReports"));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <GoogleOAuthProvider clientId="545996792916-v6vfars25uhgajvhvrose47n9185ot5a.apps.googleusercontent.com">
      <Suspense>
        <Routes>
          <Route
            path="/"
            element={<DefaultLayout companycode=":companycode" />}
          >
            {/* Render the Home component for the root path */}
            <Route index element={<Home />} />
            {/* Enforce the presence of the companycode parameter with a regex pattern */}
            <Route
              path=":companycode:model:count:demo"
              element={<Home />}
              caseSensitive={true}
            />
            <Route path="announcement">
              <Route path="" element={<Announcement />} />
              <Route path=":id" element={<AnnouncementDetail />} />
            </Route>
            <Route path="financial-reports" element={<FinancialReports />} />
          </Route>
          {/* <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        /> */}
        </Routes>
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
