import { combineReducers } from "redux";
import authSlice from "./slice/auth";
import companySlice from "./slice/company";
import announcementSlice from "./slice/announcements";
import toastSlice from "./slice/toast";
import wiseConfigSlice from "./slice/wiseConfig";
import tickerSlice from "./slice/tickerSlice";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  company: companySlice.reducer,
  wiseConfig: wiseConfigSlice.reducer,
  announcement: announcementSlice.reducer,
  toast: toastSlice.reducer,
  ticker: tickerSlice.reducer,
});
