export default {
  apiBaseUrl: process.env.REACT_APP_MASTER_API_BASE_URL,
  apiBaseUrlNgrok: process.env.REACT_APP_MASTER_API_BASE_URL_2,
  authBaseUrl: process.env.REACT_APP_MASTER_API_BASE_URL_3,
  externalAuthUrl: process.env.REACT_APP_EXTERNAL_AUTH_URL,
  log: false,
  trackHttpTimeInConsole: false, // Works when log is true
  trackHttpResponseInConsole: false, // Works when log is true,
  apiPdfUrl : process.env.REACT_APP_REPORT_PDF_URL,
};
