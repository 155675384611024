/** For live data */
// const cardConfig = {
//   upcoming: [
//     "FINANCIALEVENTS",
//     "EARNINGSUBSCRIBE",
//     "EARNINGMARKET",
//     // "FINANCIALREMAINDER",
//   ],
//   today: {
//     preMarket: [
//       "TICKERPERFORMANCE",
//       "PEERSPROFITLOSSCARD",
//       "INDICESPROFITLOSSCARD",
//     ],
//     marketOpen: ["TICKERCHARTTODAYLARGE", "PEERSCHARTTODAYLARGE"],
//     marketClose: ["TICKERPERFORMANCE"],
//   },
//   weekly: [
//     // "TICKERCHARTWEEKLYLARGE",
//     "PROFITANDLOSS",
//     // "PEERSPROFITANDLOSS",  // query not available
//     "COMPOUNDGROWTHCARD",
//     "FINANCIALPROFITABILITYLARGE",
//     "EPSANNUALRATIOCHART",
//     "RETURNONASSETSRATIOCHART",
//     "FINANCIALPROFITABILITY",
//     "VALUATIONRATIOCHART",
//     "FINANCIALHEALTH",
//     "DEBTRATIODOTCHART",
//     "DOCUMENTTAB",
//     "NETINCOME",
//     "LASTDIVIDENDPAID",
//     "DIVIDENDLINECHART",
//     "EARNINGSCANDLECHART",
//     "ANALYSTRATINGMETERCHART",
//     "ANALYSTRANGESLIDER",
//     // "EARNINGSUBSCRIBEAUDIOPLAYER",

//     // "TICKERCHARTWEEKLY",

//     // "REVENUESTREAM",
//     // "REVENUESTEAMBYCOUNTRY",

//     // "NEWSANNOUNCEMENTS",
//     // "SHAREHOLDINGPATTERNLARGE",
//   ],
//   monthly: [
//     // "TICKERCHARTMONTHLYLARGE",
//     "REVENUEANNUALBARCHARTXL",
//     "EBITDAANNUALBARCHARTXL",
//     "REVENUEANNUAL",
//     "TRENDCHARTXL",
//     "INDICESCOMPARISONCHARTLARGE",
//     "PEERCOMPARISONCHARTLARGE",
//     "REVENUESTREAM",
//     "REVENUESTEAMBYCOUNTRY",
//     // "PROFITANDLOSS",
//     // "TRANSCRIPTCARD",

//     // "TICKERCHARTMONTHLY",
//     // "REVENUEANDEARNINGS",
//   ],
//   news: ["NEWSANNOUNCEMENTS"],
//   qa: [
//     // "QUESTIONANSWER",
//     "RATINGCHECKBOX1",
//     "RATINGCHECKBOX2",
//     "RATINGCHECKBOX3",
//     "MEETINGREQUEST",
//     // "TEXTRESPONSES",
//   ],
// };

/** For demo data  */
const cardConfig = {
  upcoming: [
    "FINANCIALEVENTS",
    "EARNINGMARKET",
    "EARNINGSUBSCRIBE",
    "FINANCIALREMAINDER",
  ],
  today: {
    preMarket: [
      "TICKERPERFORMANCE",
      "PEERSPROFITLOSSCARD",
      "INDICESPROFITLOSSCARD",
    ],
    marketOpen: ["TICKERCHARTTODAYLARGE", "PEERSCHARTTODAYLARGE"],
    marketClose: ["TICKERPERFORMANCE"],
  },
  weekly: [
    "EARNINGSUBSCRIBEAUDIOPLAYER",
    "PROFITANDLOSS",
    "PEERSPROFITANDLOSS",
    "ANALYSTRATINGMETERCHART",
    "FINANCIALHEALTH",
    "FINANCIALPROFITABILITY",
    "EPSANNUALRATIOCHART",
    "RETURNONASSETSRATIOCHART",
    "NETINCOME",
    "DEBTRATIODOTCHART",
    "VALUATIONRATIOCHART",
    "DIVIDENDLINECHART",
    "LASTDIVIDENDPAID",
    "DOCUMENTTAB",
  ],
  monthly: [
    "INDICESCOMPARISONCHARTLARGE",
    "COMPOUNDGROWTHCARD",
    "TRANSCRIPTCARD",
    "TRENDCHARTXL",
    "PEERCOMPARISONCHARTLARGE",
    "EBITDAANNUALBARCHARTXL",
    "REVENUEANNUALBARCHARTXL",
    "REVENUEANDEARNINGS",
    "REVENUEANNUAL",
    "EARNINGSCANDLECHART",
    // "SHAREHOLDINGPATTERNLARGE",
    "REVENUESTREAM",
    "ANALYSTRANGESLIDER",
    "REVENUESTEAMBYCOUNTRY",
  ],
  news: ["NEWSANNOUNCEMENTS"],
  qa: [
    "RATINGCHECKBOX1",
    "RATINGCHECKBOX2",
    "RATINGCHECKBOX3",
    "MEETINGREQUEST",
  ],
};

export default cardConfig;

/** For Annual Report 

const annualReportCardConfig = [
"EARNINGSCANDLECHART",
"EARNINGSANNUAL",
"DEBTRATIODOTCHART",
"PROTITANDLOSS",
"FINANCIALPROFITABILITY",
"EPSANNUALRATIOCHART",
"FINANCIALHEALTH",
"DOCUMENTTAB",
"NETINCOME",
"REVENUEANNUAL",
"EBITDAANNUALBARCHARTXL",
]

*/

/** For Quarterly or Interim Report 

const quarterlyOrInterimReportCardConfig = [
"RETURNONASSETSRATIOCHART",
"VALUATIONRATIOCHART",
"DOCUMENTTAB",
]

*/
