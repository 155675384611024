import { createSlice } from "@reduxjs/toolkit";
import { getCompanyInfoAction } from "../actions/companyAction";

const initialState = {
  data: [],
  searchHistory: [],
  loading: false,
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSearchHistory: (state, action) => {
      const data = action.payload;
      state.searchHistory = [...state.searchHistory, data];
    },
    setCompanyInfo: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    /** COMPANY INFORMATION */
    builder.addCase(getCompanyInfoAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompanyInfoAction.fulfilled, (state, action) => {
      const data = action.payload.data.company;
      state.data = data;
      state.loading = false;
    });
    builder.addCase(getCompanyInfoAction.rejected, (state, action) => {
      state.fetchError = action.error;
      state.loading = false;
    });
  },
});
export const { setSearchHistory } = companySlice.actions;
export const { setCompanyInfo } = companySlice.actions;
export const selectPrimaryMarketIds = (state) =>
  state.company.wiseAppConfig?.accessConfig?.primaryMarket || [];
export default companySlice;
